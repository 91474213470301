import { RowInputFields } from "../types";

const map: {
  [key: string]: string;
} = {
  account: "Account",
  busUnit: "Business Unit",
  speedChart: "SpeedChart",
  analysisType: "Analysis Type",
  monetaryAmt: "Monetary Amount",
  lineDesc: "Line Description",
  lineRef: "Line Reference",
  fund: "Fund",
  deptid: "Dept ID",
  program: "Program",
  class: "Class",
  project: "Project/Grant",
};

export const exportToExcel = (data: RowInputFields[], headerDesc: string) => {
  const dataKeys = [
    "busUnit",
    "account",
    "speedChart",
    "fund",
    "deptid",
    "program",
    "class",
    "project",
    "analysisType",
    "monetaryAmt",
    "lineDesc",
    "lineRef",
  ];

  // create headers
  let csv = "Header Description," + headerDesc + "\n";
  const headers: string[] = [];
  dataKeys.forEach((key) => headers.push(map[key]));
  csv += `${headers}\n`;

  data
    .filter((x) => x) // remove undefined values
    .forEach((record) => {
      const fields: any[] = [];
      dataKeys.forEach((key) => fields.push(record[key]));
      csv += `${fields}\n`;
    });

  const file = new File([csv], "journalupload.csv", {
    type: "text/csv",
  });

  return window.URL.createObjectURL(file);
};
