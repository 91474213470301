interface RowHeaderProps {
  text: string;
}

export const RowHeader = ({ text }: RowHeaderProps) => {
  return (
    <th role="columnheader" className="font-normal">
      {text}
    </th>
  );
};
