interface CellProps {
  value: string | Date | any;
  onChange: any;
  onBlur: (x: any) => void;
  style?: {};
  labelText: string;
  extraClass?: string;
  readonly?: boolean;
  pattern?: string;
  label: string;
}

export const Cell = ({
  extraClass = "",
  labelText,
  value,
  onChange,
  onBlur,
  style,
  readonly,
  pattern,
  label,
}: CellProps) => {
  return (
    <td className={extraClass}>
      <label
        htmlFor={labelText}
        className="h-0 w-0 absolute inset-0 inline"
        style={{
          clip: "rect(0, 0, 0, 0)",
        }}
      >
        {label}
      </label>
      <input
        id={labelText}
        value={value}
        onChange={(e) => onChange(e)}
        style={style}
        className="px-1 border rounded-sm w-full bg-lineitem"
        readOnly={readonly}
        pattern={pattern}
        onBlur={onBlur}
      />
    </td>
  );
};
