import { Option, Select, Input } from "../../base";
import React, { useMemo } from "react";
import { DatePickerInput } from "../../base/components/datepickerInput";

interface OptionPanelProps {
  reversalDate: {
    set: (d: Date) => void;
    get: () => Date | undefined;
  };
  journalDate: {
    set: (d: Date) => void;
    get: () => Date | undefined;
  };
  setReversalCode: React.Dispatch<React.SetStateAction<string>>;
  reversalCode: string;
  ledger: string;
  busUnit: string;
  setBusUnit: React.Dispatch<React.SetStateAction<string>>;
  setLedger: React.Dispatch<React.SetStateAction<string>>;
  uploadState: {
    set: (key: string, state: string) => void;
    get: (key: string) => string;
  };
}

export const OptionPanel = ({
  reversalDate,
  journalDate,
  reversalCode,
  setReversalCode,
  uploadState,
  busUnit,
  setBusUnit,
  ledger,
  setLedger,
}: OptionPanelProps) => {
  const uploadFields = useMemo(
    () =>
      [
        { label: "Journal ID", key: "journalId", max: 10 },
        {
          label: "Journal Source",
          key: "journalSource",
          max: 3,
          required: true,
        },
        { label: "Header Description", key: "headerDescription", max: 30 },
        { label: "Prepared By", key: "preparedBy", max: 8 },
      ].map(({ label, key, max, required }) => (
        <React.Fragment key={key}>
          <Input
            labelText={label}
            value={uploadState.get(key)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              uploadState.set(
                key,
                key === "headerDescription" || key === "preparedBy"
                  ? String(e.target.value).slice(0, max)
                  : String(e.target.value).slice(0, max).toUpperCase()
              )
            }
            required={required || false}
          />
        </React.Fragment>
      )),
    [uploadState]
  );

  const speedchartOpts = useMemo(
    () =>
      [
        "",
        "UMAMH",
        "UMBOS",
        "UMCEN",
        "UMDAR",
        "UMLOW",
        "UMWOR",
        "WCCCP",
        "WCPSP",
        "WCS00",
        "WCS01",
        "WCS02",
        "WCS03",
        "WCS04",
        "WCS05",
        "WCS06",
        "WCS07",
        "WCS08",
        "WCS09",
        "WCS10",
        "WCS11",
        "WUMMF",
        "UMFND",
        "EL600",
        "EL610",
        "EL650",
      ].map((x) => (
        <React.Fragment key={x}>
          <Option selected={busUnit === x} value={x} text={x} />
        </React.Fragment>
      )),
    [busUnit]
  );

  const ledgerOpts = useMemo(
    () =>
      ["ACTUALS", "ADJUST"].map((x) => (
        <React.Fragment key={x}>
          <Option selected={x === ledger} value={x} text={x} />
        </React.Fragment>
      )),
    [ledger]
  );

  const reversalCodeOpts = useMemo(
    () =>
      [
        { value: "", text: "" },
        { value: "N", text: "Do Not Generate Reversal" },
        { value: "B", text: "Beginning of Next Period" },
        { value: "E", text: "End of Next Period" },
        { value: "D", text: "Date Defined by User" },
      ].map(({ text, value }) => (
        <React.Fragment key={text}>
          <Option selected={value === reversalCode} value={value} text={text} />
        </React.Fragment>
      )),
    [reversalCode]
  );

  return (
    <div className="flex flex-wrap items-end gap-4 py-6 mt-4 mb-8 border-t border-primary border-opacity-50">
      <div className="flex justify-start gap-2 items-end">
        <div className="relative">
          <DatePickerInput
            value={journalDate.get()}
            onChange={journalDate.set}
            labelText="Journal Date"
          />
        </div>
        <div className="relative">
          <DatePickerInput
            value={reversalDate.get()}
            onChange={reversalDate.set}
            labelText="Reversal Date"
          />
        </div>
        <div className="flex justify-evenly items-end gap-4 ml-2">
          <Select
            label="Business Unit"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setBusUnit(String(e.target.value).toUpperCase());
              localStorage.setItem(
                "busUnit",
                String(e.target.value).toUpperCase()
              );
            }}
            options={speedchartOpts}
            required={true}
            extraClass="flex-1"
          />
          <Select
            label="Ledger"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setLedger(String(e.target.value).toUpperCase())
            }
            options={ledgerOpts}
            extraClass="flex-1"
          />
          <Select
            label="Reversal Code"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setReversalCode(String(e.target.value).toUpperCase())
            }
            options={reversalCodeOpts}
            extraClass="flex-1"
          />
        </div>
      </div>
      <div
        className="flex flex-1 gap-2 justify-start h-full basis-10"
        style={{
          minWidth: "600px",
        }}
      >
        {uploadFields}
      </div>
    </div>
  );
};
