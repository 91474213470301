import { AiOutlineFileExcel } from "react-icons/ai";

export const acceptedTypes = [
  "text/csv",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const DropZone = ({ type }: { type: string }) => {
  const dropZoneMessage = () => {
    if (acceptedTypes.includes(type)) {
      return (
        <>
          <div className="text-6xl grid place-items-center text-primary">
            <AiOutlineFileExcel />
          </div>
          <p>Drop your file here!</p>
        </>
      );
    } else {
      return (
        <>
          <p style={{ color: "#DF2049", fontWeight: 600 }}>
            This is not an accepted file type!
          </p>
        </>
      );
    }
  };

  return (
    <div
      style={{
        borderColor: acceptedTypes.includes(type) ? "#005eb8" : "#DF2049",
        backgroundColor: acceptedTypes.includes(type)
          ? "#005eb810"
          : "#DF204940",
      }}
      className="z-10 border-2 border-dashed border-primary rounded-sm grid place-items-center mb-3 bg-primary bg-opacity-10"
    >
      <div className="py-32 text-xl flex flex-col gap-3 pointer-events-none">
        {dropZoneMessage()}
      </div>
    </div>
  );
};
