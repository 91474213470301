import { Cell } from "./components/Cell/Cell";
import { RowInputFields } from "../../../types";
import React from "react";

interface RowProps {
  busUnit: string;
  speedChart: string;
  account: string;
  fund: string;
  deptId: string;
  program: string;
  klass: string;
  project: string;
  analysisType: string;
  monetaryAmt: string;
  lineDesc: string;
  lineRef: string;
  getOnChange: (key: string) => Function;
  rowValues: RowInputFields[];
  index: number;
}

const Row = function ({
  busUnit,
  speedChart,
  account,
  fund,
  deptId,
  program,
  klass,
  project,
  analysisType,
  monetaryAmt,
  lineDesc,
  lineRef,
  getOnChange,
  rowValues,
  index,
}: RowProps) {
  const lineItems = [
    {
      value: busUnit,
      name: "busUnit",
      style: { background: "#FFF" },
      label: "Business Unit",
    },
    {
      value: account,
      name: "account",
      style: { background: "#FFF" },
      label: "Account",
    },
    {
      value: speedChart,
      name: "speedChart",
      style: { background: "#FFF" },
      label: "SpeedChart",
    },
    { value: fund, name: "fund", label: "Fund" },
    { value: deptId, name: "deptid", label: "DeptID" },
    { value: program, name: "program", label: "Program" },
    { value: klass, name: "class", label: "Class" },
    { value: project, name: "project", label: "Project/Game" },
    {
      value: analysisType,
      name: "analysisType",
      style: { background: "#FFF" },
      label: "Analysis Type",
    },
    {
      value: monetaryAmt,
      name: "monetaryAmt",
      style: { background: "#FFF" },
      label: "$ Amount",
      onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const onChange = getOnChange("monetaryAmt");
        onChange({ target: { value: parseFloat(value).toFixed(2) } });
      },
    },
    {
      value: lineDesc,
      name: "lineDesc",
      style: { background: "#FFF" },
      label: "Line Desc.",
    },
    {
      value: lineRef,
      name: "lineRef",
      style: { background: "#FFF" },
      label: "Line Ref.",
    },
  ].map(({ value, name, style, onBlur, label }) => (
    <React.Fragment key={name + index}>
      <Cell
        value={value}
        onChange={getOnChange(name)}
        onBlur={onBlur ? onBlur : (_: any) => {}}
        labelText={name + index}
        label={label}
        readonly={false}
        style={style}
      />
    </React.Fragment>
  ));

  return (
    <tr>
      <th className="font-normal">{index + 1}</th>
      {lineItems}
    </tr>
  );
};
export default Row;
