import { getFullSum } from "../components/TableWrapper/TableDetail/TableDetail";
import { RowInputFields } from "../types";

interface UploadFileData {
  rowValues: RowInputFields[];
  busUnit: string;
  journalId: string;
  reversalDate: Date;
  journalDate: Date;
  headerReference: string;
  headerDescription: string;
  reversalId: string;
  ledger: string;
  journalSource: string;
  monetaryAmounts: string[];
}

// godspeed
export const createUploadFile = ({
  rowValues,
  busUnit,
  journalId,
  reversalDate,
  journalDate,
  headerReference,
  headerDescription,
  reversalId,
  ledger,
  journalSource,
  monetaryAmounts,
}: UploadFileData) => {
  /*
  All strings need "default" lengths. IE, for a field with a max length of 10, if 7 characters are provided, it must be padded with 3 spaces

  journalId = 10
  jounralDate = 8
  ledger = 7
  reversalCd = 1
  reversalDate = 8
  journalSource = 3
  headerReference = 8
  headerDescription = 30
*/
  const netDebits = parseFloat(getFullSum(monetaryAmounts).replace("$", ""));
  const netDebitsZero = netDebits === 0;
  let upload = "";
  let rDate = "";
  let jDate = "";

  if (reversalDate) {
    reversalDate = new Date(reversalDate);
    const revMonth = reversalDate.getUTCMonth() + 1;
    rDate += revMonth.toString().length === 1 ? "0" + revMonth : revMonth;
    rDate += reversalDate.getUTCDate();
    rDate += reversalDate.getUTCFullYear();
  }

  if (journalDate) {
    journalDate = new Date(journalDate);
    const journalMonth = journalDate.getUTCMonth() + 1;
    const journalDay = journalDate.getUTCDate();
    jDate +=
      journalMonth.toString().length === 1 ? "0" + journalMonth : journalMonth;
    jDate += journalDay.toString().length === 1 ? "0" + journalDay : journalDay;
    jDate += journalDate.getUTCFullYear();
  }

  // matches flat file config seen here:
  // https://docs.oracle.com/cd/F56182_01/fscm92pbr44/eng/fscm/fglr/UsingtheFlatFileJournalImportProcess-9f4ba7.html

  upload +=
    "H" + // col 1 - H
    pad(5, busUnit ?? "UMBOS") + // col 2 - business unit
    pad(10, journalId) + // col 7 - journal id
    pad(8, jDate) + // col 17 - journal date
    pad(1) + // col 25 - adjusting entry
    pad(3) + // col 26 - adjusting period
    pad(8) + // col 29 - ADB average daily balance (mmddyyyy)
    pad(10, ledger) + // col 37 - ledger group
    pad(10) + // col 47 - ledger
    pad(1, reversalId[0]) + // col 57 - reversal code
    pad(8, rDate) + // col 58  - reversal date
    pad(3) + // col 66 - reversal adjusting period (only used when reversal code is 'U', which is not used)
    pad(1) + // col 69 - ADB reversal code
    pad(8) + // col 70 - ADB reversal date
    pad(3, journalSource) + // col 78 - journal source
    pad(8, headerReference) + // col 81 - transaction reference number
    pad(30, headerDescription) + // col 89 - description
    "USD" + // col 119 - default currency code
    "CRRNT" + // col 122 - default currency rate type
    pad(8, jDate) + // col 127 - currency effective date - appears by default as journal date
    pad(17, "1") + // col 135 - default currency exchange rate
    pad(3) + // col 152 - system source
    pad(8) + // col 155 - doc type for doc sequencing
    pad(12) + // col 163 - doc sequence number
    pad(1) + // col 175 - budget header status
    pad(1) + // col 176 - commitment control amount type
    pad(4) + // col 177 - GL adjustment type
    pad(10) + // col 181 - journal class
    pad(254) + // col 191 - DESCR254
    "\n";
  rowValues.forEach((row, counter) => {
    upload +=
      "L" + // col 1 - L
      pad(5, row.busUnit ?? "") + // col 2 - business unit
      String("0").repeat(9 - (counter + 1).toString().length) +
      (counter + 1).toString() + // col 7 - journal line number
      pad(10, ledger) + // col 16 - ledger
      pad(10, row.account ?? "") + // col 26 - account
      pad(10) + // col 36 - alternate account
      pad(10, row.deptid ?? "") + // col 46 - department
      pad(8) + // col 56 - operating unit
      pad(6) + // col 64 - product
      pad(5, row.fund ?? "") + // col 70 - fund code
      pad(5, row.class ?? "") + // col 75 - class field
      pad(5, row.program ?? "") + // col 80 - program code
      pad(8) + // col 85 - budget reference
      pad(5) + // col 93 - affiliate
      pad(10) + // col 98 - fund affiliate
      pad(10) + // col 108 - operating unit affiliate
      pad(10) + // col 118 - chartfield 1
      pad(10) + // col 128 - chartfield 2
      pad(10) + // col 138 - chartfield 3
      pad(15, row.project ?? "") + // col 148 - project
      pad(4) + // col 163 - book code
      pad(8) + // col 167 - budget period
      pad(10) + // col 175 - scenario
      pad(3) + // col 185 - statistics code
      pad(1, row.monetaryAmt[0] === "-" ? "-" : "0") + // col 188 - base currency amount
      LPad(
        27,
        parseFloat(row.monetaryAmt).toFixed(2).replace("-", "") ?? "",
        "0"
      ) + // MASSIVE 0 PADDING
      //Const FMT_CURRENCY = "0000000000000000000000000.00;-000000000000000000000000.00"
      pad(1) + // col 216 - movement flag
      pad(17) + // col 217 - statistics amount
      pad(10, row.lineRef ?? "") + // col 234 - journal line reference
      pad(30, row.lineDesc ?? "") + // col 244 - journal line description
      "USD" + // col 274 - currency code
      "CRRNT" + // col 277 - currency rate type
      // (row.monetaryAmt[0] === "-" ? LPad(1, "0") : "-") + // col 282 - amount
      pad(1, row.monetaryAmt[0] === "-" ? "-" : "0") + // col 282 - amount
      LPad(
        27,
        parseFloat(row.monetaryAmt).toFixed(2).replace("-", "") ?? "",
        "0"
      ) + // MASSIVE 0 PADDING
      pad(17, "1"); // col 310 - currency exchange rate
    // pad(5, row.busUnit) + // col 327 - project business unit
    // pad(15, "UM01") + // col 332 - projects activity id
    // pad(3, row.analysisType ? row.analysisType : "GLE");
    // col 347 - project analysis type

    if (!Boolean(row.project)) {
      upload += pad(23);
    } else {
      // upload += pad(5, row.busUnit) + "UM01";
      // upload += Boolean(row.analysisType) ? pad(3, row.analysisType) : "GLE";
      upload +=
        pad(5, row.busUnit) + // col 327 - project business unit
        pad(15, "UM01") + // col 332 - projects activity id
        pad(3, row.analysisType ? row.analysisType : "GLE");
    }
    upload +=
      pad(5) + // col 350 - projects resource type
      pad(5) + // col 355 - projects resource category
      pad(5) + // col 360 - projects resource sub-category
      pad(8) + // col 365 - budget date
      pad(1) + // col 373 - budget line status
      pad(10) + // col 374 - entry event
      pad(4) + // col 384 - inter/intra-unit transaction group number
      pad(1) + // col 388 - inter/intra-unit anchor flag
      pad(30) + // col 389 - open item key
      "\n";
  });

  //End of loop - file 'footer'

  upload +=
    "C" + // col 1 - C
    (busUnit ?? "UMBOS") + // col 2 - control business unit
    pad(10) + // col 7 - ledger
    // ...
    pad(289); // col 17 - col 306

  const file = new File([upload], "journalupload.txt", {
    type: "text/plain",
  });

  return netDebitsZero ? window.URL.createObjectURL(file) : "netDebitsError";
};

// pads an amount of empty spaces after a value
const pad = (amt: number, val: string = "", repeatVal: string = " ") =>
  (val + String(repeatVal).repeat(amt - val.slice(0, amt).length)).slice(
    0,
    amt
  );

const LPad = (amt: number, val: string = "", repeatVal: string = " ") =>
  String(repeatVal).repeat(amt - val.length) + val;
