import $ from "jquery";

interface VisibilityState {
  set: (val: boolean) => void;
  get: boolean;
}

export const attachListeners = (
  eventTarget: string,
  listeners: string[],
  vis: VisibilityState
) => {
  const listener = (e: any) => {
    if (vis.get && e?.key && e?.keyCode) {
      e.key === "Escape" && e.keyCode === 27 && vis.set(false);
    } else if (vis.get) {
      const target = $(`#${eventTarget}`).find(e.target);
      target?.length === 0 && vis.set(false);
    }
  };

  listeners.forEach((event) => window.addEventListener(event, listener));

  return () => {
    listeners.forEach((event) => window.removeEventListener(event, listener));
  };
};
