import styled from "styled-components";

interface InputProps {
  value: string | Date | any;
  onChange: any;
  type?: string;
  style?: {};
  labelText: string;
  extraClass?: string;
  max?: number;
  required?: boolean;
}

const Placeholder = styled.label<{ focused: boolean; required: boolean }>`
  top: ${({ focused }: { focused: boolean }) => (focused ? 0 : "25px")};
  font-size: ${({ focused }: { focused: boolean }) =>
    focused ? "0.9rem" : "1rem"};
  pointer-events: none;

  &:focus {
    outline: none;
  }

  ${({ required }: { required: boolean }) =>
    required
      ? `
    &:after {
      content: "*";
      color: red;
      font-size: 1rem;
    }
  `
      : ""}
`;

export default function Input({
  value,
  onChange,
  type,
  style,
  labelText,
  extraClass,
  max,
  required,
}: InputProps) {
  const inputProps = {
    id: labelText,
    type: type === "filter" ? "number" : "text",
    value: value,
    onChange: (e: any) => onChange(e),
    style: style,
    max: max,
    className:
      "w-full h-10 align-middle mt-3 px-2 py-3 pb-1 pl-0 border-primary focus:border-secondary border-b-2 focus:outline-none transition-colors",
  };

  return (
    <span
      className={"relative inline-block " + extraClass}
      style={{ gridColumn: type === "textarea" ? "span 2" : "" }}
    >
      <Placeholder
        className="absolute top-0 left-0 bg-white transition-all"
        focused={true}
        htmlFor={labelText}
        required={required}
      >
        {labelText}
      </Placeholder>
      {required ? (
        <input required {...inputProps} />
      ) : (
        <input {...inputProps} />
      )}
    </span>
  );
}
