import { useMemo } from "react";

interface TableDetailProps {
  totalLines: number;
  monetaryAmts: string[];
  rowCount: number;
}

const { format } = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const toFloat = (n: string) => {
  if (Boolean(n)) {
    return isNaN(parseFloat(n.replace(/\$/g, "")))
      ? 0
      : parseFloat(n.replace(/\$/g, ""));
  } else {
    return 0;
  }
};

export const getFullSum = (amts: string[]) =>
  format(amts.reduce((sum, n) => sum + toFloat(n), 0));

const getSumOfPositives = (amts: string[]) =>
  format(amts.reduce((sum, n) => (toFloat(n) > 0 ? sum + toFloat(n) : sum), 0));

export const TableDetail = ({
  totalLines,
  monetaryAmts,
  rowCount,
}: TableDetailProps) => {
  const totalDebits = useMemo(
    () => getSumOfPositives(monetaryAmts),
    [monetaryAmts]
  );
  const netDebits = useMemo(() => getFullSum(monetaryAmts), [monetaryAmts]);

  return (
    <div className="flex gap-8 mb-12">
      <span>
        <p className="text-primary font-semibold">Row Count</p>
        <p>{rowCount ?? 0}</p>
      </span>
      <span>
        <p className="text-primary font-semibold">Total Lines</p>
        <p>{totalLines ?? 0}</p>
      </span>
      <span>
        <p className="text-primary font-semibold">Total Debits</p>
        <p>{totalDebits ?? "$0.00"}</p>
      </span>
      <span>
        <p className="text-primary font-semibold">Net Debit/Credit</p>
        <p>{netDebits ?? "$0.00"}</p>
      </span>
    </div>
  );
};
