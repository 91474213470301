import { useState, useEffect } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import styled from "styled-components";
import { attachListeners } from "../../../util/attachListeners";

interface DatePickerProps {
  onChange: (date: Date) => void;
  vis: {
    set: (val: boolean) => void;
    get: boolean;
  };
  id?: string;
  parentId?: string;
}

const StyledDateWrapper = styled.div<{ vis: boolean }>`
  z-index: 100;
  position: relative;
  top: ${({ vis }: { vis: boolean }) => (vis ? "5px" : "-30px")};
  opacity: ${({ vis }: { vis: boolean }) => (vis ? 1 : 0)};
  transition: top 120ms ease, opacity 150ms ease;

  .rdp-day:active,
  .rdp-button:active {
    border: 2px solid #005eb8;
  }
  .rdp-day:focus,
  .rdp-button:focus {
    border-color: #005eb8;
  }

  .selected:not([disabled]) {
    background: #005eb8;
    color: white;
    font-weight: bold;
    border: 2px solid #005eb8;
  }

  .selected:hover:not([disabled]) {
    background: #005eb8;
    opacity: 80%;
  }
`;

export const DatePicker = ({
  onChange,
  vis,
  id,
  parentId,
}: DatePickerProps) => {
  const [current, setCurrent] = useState<Date>();

  useEffect(() => {
    const removeListeners = attachListeners(
      (parentId as string) || "",
      ["click", "keydown"],
      vis
    );

    return removeListeners;
  }, [vis, id, parentId]);

  return (
    <StyledDateWrapper vis={vis.get}>
      <div
        className="absolute top-0 left-0 z-50 shadow-2xl rounded-md text-black bg-white"
        style={{ display: vis.get ? "flex" : "none" }}
        id={id}
      >
        <DayPicker
          onDayClick={(date: Date) => {
            onChange(date);
            setCurrent(date);
            vis.set(false);
          }}
          selected={current}
          modifiersClassNames={{
            selected: "selected",
          }}
        />
      </div>
    </StyledDateWrapper>
  );
};
