import axios from "axios";
import {
  RowInputFields,
  PeopleSoftResponse,
  InvalidFieldsResponse,
  PeopleSoftRequestObject,
} from "../types";

export const callWebService = async (vals: RowInputFields[]) => {
  try {
    const rows: RowInputFields[] = [];
    const errors: InvalidFieldsResponse[] = [];
    const batchSize = 500;
    const batches = Math.ceil(vals.length / batchSize);

    for (let batch = 0; batch < batches; batch++) {
      const start = batch * batchSize;
      const end = start + batchSize;
      const batchRecords = vals.slice(start, end);
      const data = batchRecords.map((v) => {
        const rec = {} as PeopleSoftRequestObject;
        if (v.busUnit) rec.business_unit = v.busUnit;
        if (v.speedChart) rec.speedchart = v.speedChart;
        rec.index = v.index;
        return rec;
      });
      const srvRecords: PeopleSoftResponse = await axios
        .post("/api/webservice/", { data })
        .then(({ data }) => data);

      rows.push(...srvRecords.rows.map(buildRecords(vals)));
      errors.push(...srvRecords.errors);
    }

    return { rows, errors };
  } catch (err) {
    console.log("error", err);
    return Promise.resolve(vals);
  }
};

const buildRecords = (vals: RowInputFields[]) => (row: RowInputFields) => {
  const r = vals.find((v) => v.index === row.index) as RowInputFields;
  return {
    ...r,
    class: row.class,
    deptid: row.deptid,
    fund: row.fund,
    program: row.program,
    project: row.project,
  };
};
