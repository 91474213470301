import styled from "styled-components";
import { useState } from "react";
import { DatePicker } from "./DatePicker";
import { ToolTip } from "./tooltip";
import { AiFillCalendar } from "react-icons/ai";
import { MdClear } from "react-icons/md";

interface DatePickerInputProps {
  value: string | Date | any;
  onChange: any;
  style?: {};
  labelText: string;
  extraClass?: string;
  max?: number;
  required?: boolean;
}

const Placeholder = styled.label<{ focused: boolean; required: boolean }>`
  top: ${({ focused }: { focused: boolean }) => (focused ? 0 : "25px")};
  font-size: ${({ focused }: { focused: boolean }) =>
    focused ? ".9rem" : "1rem"};
  pointer-events: none;

  &:focus {
    outline: none;
  }

  ${({ required }: { required: boolean }) =>
    required
      ? `
    &:after {
      content: "*";
      color: red;
      font-size: 1rem;
    }
  `
      : ""}

  .page-container {
  }
`;

export const DatePickerInput = ({
  value,
  onChange,
  style,
  labelText,
  extraClass,
  max,
  required,
}: DatePickerInputProps) => {
  const [datePickerVis, setDatePickerVis] = useState(false);
  const [dateFocus, setDateFocus] = useState(false);
  const [toolTipVis, setTooltipVis] = useState(false);

  const id = labelText.replace(/ /g, "");
  const fullId = "datePickerInput-" + id;
  // either the value is truthy, in which case we check its length and determine focus
  // state that way, or default to false

  return (
    <span id={fullId} className={"relative inline-block z-20 " + extraClass}>
      <Placeholder
        className="absolute top-0 left-0 bg-white transition-all"
        focused={true}
        htmlFor={labelText}
        required={required}
      >
        {labelText}
      </Placeholder>
      <input
        id={labelText}
        value={value}
        onChange={(e) => e.preventDefault()}
        style={style}
        onFocusCapture={() => setDateFocus(true)}
        onBlurCapture={() => {
          setTooltipVis(false);
          setDateFocus(false);
        }}
        onKeyDown={(e) => {
          if (e.key === "Tab") {
            setTooltipVis(false);
            return;
          }
          e.preventDefault();
          dateFocus && setTooltipVis(true);
        }}
        className="w-full h-10 align-middle mt-3 px-2 py-3 pb-1 pl-0 border-primary focus:border-secondary border-b-2 focus:outline-none transition-colors"
        max={max}
      />
      <div className="absolute right-0 bottom-1 flex flex-row gap-1 items-end">
        {value?.length > 0 ? (
          <button
            onClick={() => onChange("")}
            className=" right-6 bottom-1 text-lg text-danger font-bold cursor-pointer"
            aria-label="Clear the selected date"
          >
            <MdClear />
          </button>
        ) : (
          <div />
        )}
        <button
          onClick={() => setDatePickerVis(!datePickerVis)}
          aria-label="Toggle calendar to select a date"
        >
          <AiFillCalendar
            style={{
              color: datePickerVis ? "#FCB316" : "#005EB8",
              transition: "color 100ms ease",
            }}
            className="cursor-pointer text-xl  right-0 bottom-1 text-primary"
          />
        </button>
      </div>
      <DatePicker
        vis={{ set: setDatePickerVis, get: datePickerVis }}
        id={id}
        parentId={fullId}
        onChange={(e) => {
          const d = new Date(e);
          const month = d.getUTCMonth() + 1;
          const day = d.getUTCDate();
          const year = d.getUTCFullYear();
          const dateString = [month, day, year].join("/");
          onChange(dateString);
        }}
      />
      <ToolTip
        vis={toolTipVis}
        text="Please click the calendar icon to select a date."
      />
    </span>
  );
};
