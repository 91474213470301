import styled from "styled-components";

interface SelectProps {
  label: string;
  options?: JSX.Element[];
  onChange: any;
  extraClass?: string;
  required?: boolean;
}

const Placeholder = styled.label<{ focused: boolean; required: boolean }>`
  top: ${({ focused }: { focused: boolean }) => (focused ? "3px" : "29px")};
  font-size: ${({ focused }: { focused: boolean }) =>
    focused ? ".9rem" : "1rem"};
  pointer-events: none;

  &:focus {
    outline: none;
  }

  ${({ required }: { required: boolean }) =>
    required
      ? `
    &:after {
      content: "*";
      color: red;
      font-size: 1rem;
    }
  `
      : ""}
`;

export default function Select({
  label,
  options,
  onChange,
  extraClass,
  required,
}: SelectProps) {
  const selectProps = {
    onChange,
    id: label,
    className: "h-1/2 w-full self-end pb-1 -mx-1 bg-white",
  };

  return (
    <span
      className={
        "flex justify-between border-b-2 border-primary relative h-14 " +
        extraClass
      }
    >
      <Placeholder
        htmlFor={label}
        focused={true}
        className="absolute transition-all whitespace-nowrap"
        required={Boolean(required)}
      >
        {label}
      </Placeholder>
      {required ? (
        <select required {...selectProps}>
          {options}
        </select>
      ) : (
        <select {...selectProps}>{options}</select>
      )}
    </span>
  );
}
