import { Button } from "../base/";
import styled from "styled-components";
import { useEffect } from "react";
import { attachListeners } from "../../util";

interface StatusModalProps {
  vis: boolean;
  message: string;
  hide: () => void;
}

const StyledModal = styled.div`
  #shadowed {
    box-shadow: 0 -2px 6px #00000010, 0 0 18px #00000015, 0 15px 32px #00000015;
  }

  z-index: 30;
  animation: drop-in 100ms ease;

  @keyframes drop-in {
    from {
      opacity: 0;
    }
  }
`;

export default function StatusModal({ vis, message, hide }: StatusModalProps) {
  useEffect(() => {
    return attachListeners("", ["keydown"], {
      get: vis,
      set: (_: boolean) => hide(),
    });
  });

  return (
    <StyledModal
      style={{ display: vis ? "flex" : "none" }}
      id="transaction-modal-wrap"
      onClick={(e: any) => {
        if (e.target.id === "transaction-modal-wrap") hide();
      }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10"
      role="alertdialog"
    >
      <div
        id="shadowed"
        className="container relative -top-32 w-1/2 p-6 bg-white border-gray-50 rounded border-px"
      >
        <p className="mb-4 text-center">{message}</p>
        <Button onClick={hide} text="Continue" style={{ width: "100%" }} />
      </div>
    </StyledModal>
  );
}
