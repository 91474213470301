interface ButtonProps {
  text: string;
  onClick: any;
  style?: {};
  extraClass?: string;
  type?: "submit" | "button";
  children?: React.ReactNode;
  describedBy?: string;
}

export default function Button({
  text,
  onClick,
  style,
  extraClass,
  type = "submit",
  children,
  describedBy = "",
}: ButtonProps) {
  return (
    <button
      type={type}
      onClick={onClick}
      style={style}
      className={
        "align-middle px-4 py-2 shadow-md bg-primary text-white filter hover:brightness-110 active:brightness-90 focus:outline-gold rounded-sm " +
        extraClass
      }
      aria-describedby={describedBy}
    >
      {text ? text : children ? children : ""}
    </button>
  );
}
