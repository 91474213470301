import React, { useCallback, useEffect, useState } from "react";
import { ActionContainer } from "./ActionContainer/ActionContainer";
import RowContainer from "../LineItemContainer/RowContainer";
import { Paginator } from "./Paginator/Paginator";
import { OptionPanel } from "./OptionPanel/OptionPanel";
import StatusModal from "../Modals/Status";
import { TableDetail } from "./TableDetail/TableDetail";
import { validations } from "../../util/validations";
import { Logo } from "./Logo/Logo";
import {
  InvalidFieldsResponse,
  PeopleSoftResponse,
  RowInputFields,
  UploadStates,
} from "../../types";
import { ErrorDisplay } from "../Modals/ErrorDisplay";
import {} from "../../types";
import {
  getParseMethod,
  handleCSVParsing,
  handleXLSParsing,
  handleXLSXParsing,
} from "../../util/handleFileParsing";

const RESULTS_PER_PAGE = 20;

export const TableWrapper = () => {
  const [rowCount, setRowCount] = useState(10);
  const [rowValues, setRowValues] = useState<RowInputFields[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [journalDate, setJournalDate] = useState<Date>();
  const [reversalDate, setreversalDate] = useState<Date>();
  const [reversalCode, setReversalCode] = useState("");
  const [ledger, setLedger] = useState("ACTUALS");
  const [modalVis, setModalVis] = useState(false);
  const [modalText, setModalText] = useState("");
  const [errorDisplayVis, setErrorDisplayVis] = useState(false);
  const [errors, setErrors] = useState<InvalidFieldsResponse[]>([]);
  const [busUnit, setBusUnit] = useState(localStorage.getItem("busUnit") ?? "");
  const [uploadStates, setUploadStates] = useState<UploadStates>({
    journalId: "",
    journalSource: "",
    headerDescription: "",
    preparedBy: "",
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [rowCount]);

  const getRowChangeHandlerCreator = useCallback((index: number) => {
    return (key: string): Function => {
      return (e: React.ChangeEvent<HTMLInputElement>): void => {
        setRowValues((prev) => {
          const copy = [...prev];
          if (!copy[index]) {
            copy[index] = {
              index,
              busUnit: "",
              account: "",
              speedChart: "",
              fund: "",
              deptid: "",
              program: "",
              class: "",
              project: "",
              analysisType: "",
              monetaryAmt: "",
              lineDesc: "",
              lineRef: "",
            };
          }

          copy[index][key] = String(e.target.value)
            .toUpperCase()
            .slice(0, validations[key].max)
            .replace(validations[key].pattern, "");
          return copy;
        });
      };
    };
  }, []);

  const checkRequired = () => {
    return uploadStates.journalSource &&
      journalDate &&
      localStorage.getItem("busUnit") &&
      rowValues.every((row) => Boolean(row.account))
      ? true
      : false;
  };

  const getEmptyFields = () => {
    return [
      { field: uploadStates.journalSource, key: "Journal Source" },
      { field: journalDate, key: "Journal Date" },
      { field: localStorage.getItem("busUnit"), key: "Business Unit" },
      {
        field: rowValues.every((row) => Boolean(row.account)),
        key: "Account values for each row",
      },
    ]
      .filter(({ field }) => !field, [])
      .map(({ key }) => key);
  };

  const getEmptyFieldText = () => {
    const fields = getEmptyFields();

    if (fields.length === 1) {
      return fields[0].includes("Account")
        ? fields.join(", ") + " are required."
        : fields.join(", ") + " is required.";
    } else {
      const lastVal = fields[fields.length - 1];
      return (
        fields.slice(0, fields.length - 1).join(", ") +
        " and " +
        lastVal +
        " are required."
      );
    }
  };

  const popModal = useCallback((msg: string) => {
    setModalText(msg);
    setModalVis(true);
    return false;
  }, []);

  const showErrorDisplay = useCallback(() => setErrorDisplayVis(true), []);
  const hideErrorDisplay = useCallback(() => setErrorDisplayVis(false), []);

  const handleJournalUpload = (uploadFn: Function): string => {
    if (checkRequired()) {
      return uploadFn({
        rowValues: rowValues.slice(0, rowCount),
        busUnit: localStorage.getItem("busUnit"),
        reversalDate,
        journalDate,
        ledger,
        journalId: uploadStates.journalId,
        journalSource: uploadStates.journalSource,
        headerReference: uploadStates.preparedBy,
        headerDescription: uploadStates.headerDescription,
        reversalId: reversalCode,
        monetaryAmounts: rowValues
          .slice(0, rowCount)
          .filter((x) => x)
          .map((row) => row.monetaryAmt),
      });
    } else {
      popModal(getEmptyFieldText());
      return "";
    }
  };

  const handleExportToExcel = useCallback(
    (exportToExcel): string =>
      exportToExcel(
        rowValues.slice(0, rowCount),
        uploadStates.headerDescription
      ),
    [rowValues, rowCount, uploadStates.headerDescription]
  );

  const clearState = () => {
    setRowValues([]);
    setJournalDate(undefined);
    setreversalDate(undefined);
    setReversalCode("");
    setLedger("ACTUALS");

    setUploadStates({
      journalId: "",
      journalSource: "",
      headerDescription: "",
      preparedBy: "",
    });
  };

  const parseXLS = useCallback(async (file: File) => {
    const {
      rows,
      journalDate,
      reversalDate,
      busUnit,
      journalId,
      ledger,
      journalSource,
      reversalCode,
      headerDescription,
      headerRef,
    } = await handleXLSParsing(file);

    setRowCount(rows.length);
    setRowValues(rows);
    setJournalDate(journalDate);
    setreversalDate(reversalDate);
    setReversalCode(reversalCode[0]);
    setLedger(ledger);
    setBusUnit(busUnit);

    setUploadStates({
      journalId,
      journalSource,
      headerDescription,
      preparedBy: headerRef,
    });

    localStorage.setItem("busUnit", busUnit);
  }, []);

  const handleExcelImport = useCallback(
    async (file: File) => {
      const hasImportedPreviously = localStorage.getItem(
        "hasImportedPreviously"
      );

      if (!Boolean(parseInt(hasImportedPreviously!))) {
        localStorage.setItem("hasImportedPreviously", "1");
        popModal(
          "Optionally, you can also drag and drop excel files over the table to import them!"
        );
      }

      clearState();

      switch (file.type) {
        case "text/csv": // export from Journal Upload webapp
          const csv = await handleCSVParsing(file);
          setRowCount(csv.rows.length);
          setRowValues(csv.rows);
          setUploadStates({
            journalId: "",
            journalSource: "",
            preparedBy: "",
            headerDescription: csv.headerDescription,
          });
          break;
        case "application/vnd.ms-excel": // original excel file that this app replaces. for backwards compatability
          await parseXLS(file);
          break;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": // chartfield lookup export
          const parseMethod = await getParseMethod(file);

          if (parseMethod === "xls") {
            parseXLS(file);
          } else {
            const xlsx = await handleXLSXParsing(file);
            setRowCount(xlsx.length);
            setRowValues(xlsx);
          }
          break;
        default:
          popModal("That isn't a supported file type.");
          break;
      }
    },
    [popModal, parseXLS]
  );

  const handleCallWebservice = useCallback(
    async (wsCallFn: Function) =>
      wsCallFn(rowValues.slice(0, rowCount)).then(
        (wsVals: PeopleSoftResponse) => {
          setErrors([]);
          // we need to match the shape of the existing state (which includes empty spaces) when
          // creating the new state. In order to do this we create an array with an amount of empty values
          // matching the existing state, and then iterating over the returned webservice data to populate the empty array.
          const stateCopy = new Array(rowValues.length);

          rowValues.forEach((row) => {
            stateCopy[row.index] = row;
          });

          wsVals.rows.forEach((wsVal) => {
            stateCopy[wsVal.index] = {
              ...rowValues[wsVal.index],
              ...wsVal,
              account: rowValues[wsVal.index].account,
            };
          });

          if (wsVals.errors.length > 0) {
            setErrors(wsVals.errors);
            showErrorDisplay();
            wsVals.errors.forEach((error) => {
              stateCopy[error.index] = {
                ...rowValues[error.index],
              };
            });
          }
          setRowValues(stateCopy);
        }
      ),
    [rowCount, rowValues, showErrorDisplay]
  );

  return (
    <main className="container mx-auto bg-white">
      <div className="m-5 p-3 pb-1">
        <Logo />
        <h1
          className="text-4xl pb-3"
          style={{
            fontFamily: "'Raleway', sans-serif",
          }}
        >
          Journal Upload
        </h1>
        <ActionContainer
          setRowCount={setRowCount}
          actions={{
            journalUpload: handleJournalUpload,
            exportToExcel: handleExportToExcel,
            callWebService: handleCallWebservice,
            importFromExcel: handleExcelImport,
          }}
        />
        <OptionPanel
          journalDate={{
            set: setJournalDate,
            get: () => journalDate,
          }}
          reversalDate={{
            set: setreversalDate,
            get: () => reversalDate,
          }}
          reversalCode={reversalCode}
          setReversalCode={setReversalCode}
          busUnit={busUnit}
          setBusUnit={setBusUnit}
          ledger={ledger}
          setLedger={setLedger}
          uploadState={{
            set: (key: string, state: string) => {
              setUploadStates((prev) => ({
                ...prev,
                [key]: state,
              }));
            },
            get: (key: string) => uploadStates[key as keyof UploadStates],
          }}
        />
        <TableDetail
          rowCount={rowCount}
          totalLines={
            rowValues
              .slice(0, rowCount)
              .filter(
                (x) =>
                  x &&
                  x.busUnit?.replace(/ /g, "") &&
                  (x.speedChart?.toString().replace(/ /g, "") ||
                    x.account?.toString().replace(/ /g, ""))
              ).length
          }
          monetaryAmts={rowValues
            .slice(0, rowCount)
            .filter((x) => x)
            .map((row) => row.monetaryAmt)}
        />
        <Paginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxPages={Math.ceil(Number(rowCount) / RESULTS_PER_PAGE)}
        />
        <RowContainer
          rowCount={rowCount}
          getRowChangeHandler={getRowChangeHandlerCreator}
          rowValues={rowValues}
          handleExcelImport={handleExcelImport}
          pageDetail={{
            start: (currentPage - 1) * RESULTS_PER_PAGE,
            end: (currentPage - 1) * RESULTS_PER_PAGE + RESULTS_PER_PAGE,
          }}
        />
        <Paginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          maxPages={Math.ceil(Number(rowCount) / RESULTS_PER_PAGE)}
        />
        <ActionContainer
          setRowCount={setRowCount}
          actions={{
            journalUpload: handleJournalUpload,
            exportToExcel: handleExportToExcel,
            callWebService: handleCallWebservice,
            importFromExcel: handleExcelImport,
          }}
        />
        <StatusModal
          vis={modalVis}
          message={modalText}
          hide={() => setModalVis(false)}
        />
        <ErrorDisplay
          vis={errorDisplayVis}
          hide={hideErrorDisplay}
          errors={errors}
        />
      </div>
    </main>
  );
};
