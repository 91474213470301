import React, { useCallback, useMemo, useState } from "react";
import { Button, ButtonInput, ButtonLink, Input, Spinner } from "../../base";
import { callWebService } from "../../../http";
import { createUploadFile, exportToExcel } from "../../../util";
import { AiFillFileExcel } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { FaFileDownload } from "react-icons/fa";
import { RowInputFields } from "../../../types";
import StatusModal from "../../Modals/Status";
import { HiPlusCircle } from "react-icons/hi";

interface ActionContainerProps {
  setRowCount: React.Dispatch<React.SetStateAction<number>>;
  actions: {
    exportToExcel: (exportFn: Function) => string;
    callWebService: (cb: Function) => Promise<void>;
    journalUpload: (uploadFn: Function) => string;
    importFromExcel: (file: File) => void;
  };
}

export const ActionContainer = ({
  setRowCount,
  actions,
}: ActionContainerProps) => {
  const [rowCountLabel, setRowCountLabel] = useState(10);
  const [excelDownload, setExcelDownload] = useState("");
  const [uploadDownload, setUploadDownload] = useState("");
  const [spinnerVis, setSpinnerVis] = useState(false);
  const [modalVis, setModalVis] = useState(false);
  const [message, setMessage] = useState("");

  const extraButtonStyles = useMemo(
    () => "flex-1 whitespace-nowrap w-full",
    []
  );

  const onWebserviceClick = useCallback(async () => {
    try {
      setSpinnerVis(true);
      await actions.callWebService((vals: RowInputFields[]) =>
        callWebService(vals.filter((x) => x && x.busUnit && x.speedChart))
      );
    } catch (err) {
      popModal("Webservice call failed.");
    } finally {
      setSpinnerVis(false);
    }
  }, [actions]);

  const onExcelFileClick = useCallback(
    () => setExcelDownload(actions.exportToExcel(exportToExcel)),
    [actions]
  );

  const onUploadFileClick = useCallback(() => {
    const uploadFile = actions.journalUpload(createUploadFile);
    if (uploadFile === "netDebitsError") {
      setUploadDownload("");
      popModal(
        "Debits do not equal credits. Please correct the Journal Entry."
      );
    } else if (Boolean(uploadFile)) {
      setUploadDownload(uploadFile);
    }
  }, [actions]);

  const popModal = (message: string) => {
    setModalVis(true);
    setMessage(message);
  };

  const handleRowCountChange = () => {
    setRowCount(rowCountLabel);
  };

  return (
    <>
      <div className="py-3 flex flex-wrap gap-2">
        <div className="flex gap-2 w-full">
          <Button
            onClick={onWebserviceClick}
            extraClass={extraButtonStyles}
            text=""
          >
            <div className="flex items-center justify-center text-center gap-2">
              <BiRefresh className="text-2xl" />
              <p>Refresh Chartfields</p>
            </div>
          </Button>
          <Button
            text=""
            onClick={onUploadFileClick}
            extraClass={extraButtonStyles + " px-0 py-0"}
          >
            <a
              href={uploadDownload === "" ? undefined : uploadDownload}
              download={`journal-upload-${Date.now()}.txt`}
              className="flex items-center justify-center text-center gap-2 h-full w-full px-4 py-2"
            >
              <div className="flex items-center gap-2 justify-center">
                <FaFileDownload className="text-lg" />
                Create Upload File
              </div>
            </a>
          </Button>
          <ButtonLink
            onClick={onExcelFileClick}
            extraClass={extraButtonStyles}
            href={excelDownload}
          >
            <div className="h-full flex items-center justify-center text-center gap-2">
              <AiFillFileExcel className="text-xl" />
              <p className="pointer-events-none">Export To Excel</p>
            </div>
          </ButtonLink>
          <ButtonInput action={actions.importFromExcel} />
        </div>
        <div className="flex gap-2 justify-start items-end min-w-fit w-1/2">
          <Input
            labelText="Number of rows"
            value={rowCountLabel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setRowCountLabel(
                Number(
                  isNaN(Number(e.target.value)) ? 1 : Number(e.target.value)
                )
              )
            }
            type="number"
            extraClass="flex-1 min-w-max w-1/2"
            max={5000}
          />
          <Button
            text=""
            onClick={handleRowCountChange}
            extraClass={extraButtonStyles}
            style={{ height: "100%" }}
            describedBy={"# of rows to add"}
          >
            <div className="flex items-center justify-center text-center gap-2">
              <HiPlusCircle className="text-xl" />
              <p>Set Number of Rows</p>
            </div>
          </Button>
        </div>
      </div>
      <Spinner vis={spinnerVis} size="10rem" />
      <StatusModal
        vis={modalVis}
        message={message}
        hide={() => setModalVis(false)}
      />
    </>
  );
};
