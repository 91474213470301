export const validations: {
  [field: string]: {
    max: number;
    pattern: RegExp;
  };
} = {
  busUnit: {
    max: 5,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  speedChart: {
    max: 6,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  account: {
    max: 6,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  analysisType: {
    max: 3,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  monetaryAmt: {
    max: 14,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|a-zA-Z]/g, // eslint-disable-line
  },
  lineDesc: {
    max: 30,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  lineRef: {
    max: 10,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  fund: {
    max: 10,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  deptid: {
    max: 10,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  program: {
    max: 10,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  class: {
    max: 10,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
  project: {
    max: 15,
    pattern: /["'?,/\\\][{};:!@#%^&*()~`\+\_\=|\.$]/g, // eslint-disable-line
  },
};
