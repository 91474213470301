interface OptionProps {
  selected: boolean;
  value: string;
  text: string;
}

export default function Option({ selected, value, text }: OptionProps) {
  return (
    <option selected={selected} value={value}>
      {text}
    </option>
  );
}
