import styled from "styled-components";

interface ToolTipProps {
  text: string;
  vis: boolean;
}

const StyledToolTip = styled.div<{ vis: boolean }>`
  display: ${({ vis }: { vis: boolean }) => (vis ? "flex" : "none")};
  position: absolute;
  top: -50px;
  left: -10px;
  background: #4a4a4a;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  padding-bottom: 10px;
  height: fit-content;
  min-width: 12rem;
  z-index: 100;
  box-shadow: 1px 1px 2px 2px #4448;

  .point {
    height: 20px;
    width: 20px;
    transform: rotate(45deg);
    background: inherit;
    left: calc(20% - 20px);
    bottom: -10px;
    z-index: -1;
    box-shadow: 0 0 3px #4448;
  }
`;

export const ToolTip = ({ text, vis }: ToolTipProps) => {
  return (
    <StyledToolTip vis={vis} className="shadow-xl">
      <p className="">{text}</p>
      <p className="point absolute" />
    </StyledToolTip>
  );
};
