interface PaginatorProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  maxPages: number;
}

export const Paginator = ({
  currentPage,
  setCurrentPage,
  maxPages,
}: PaginatorProps) => {
  const nextPage = () =>
    setCurrentPage(currentPage + 1 > maxPages ? maxPages : currentPage + 1);
  const previousPage = () =>
    setCurrentPage(currentPage - 1 < 1 ? 1 : currentPage - 1);
  const gotoFirstPage = () => setCurrentPage(1);
  const gotoLastPage = () => setCurrentPage(maxPages);

  return (
    <nav role="navigation" aria-label="Pagination Navigation">
      <ul className="flex gap-4 pb-3 mt-6">
        <li>
          <button
            aria-label="Goto First Page"
            className="text-primary underline"
            onClick={gotoFirstPage}
          >
            First
          </button>
        </li>
        <li>
          <button
            aria-label="Goto Previous Page"
            className="text-primary underline"
            onClick={previousPage}
          >
            Previous
          </button>
        </li>
        <li>
          <button
            aria-label="Goto Next Page"
            className="text-primary underline"
            onClick={nextPage}
          >
            Next
          </button>
        </li>
        <li>
          <button
            aria-label="Goto Last Page"
            className="text-primary underline"
            onClick={gotoLastPage}
          >
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
};
