import { useCallback, useState } from "react";
import { acceptedTypes, DropZone } from "../DropZone/DropZone";

interface DropTargetProps {
  handleExcelImport: (file: File) => Promise<void>;
  children: React.ReactNode;
}
export const DropTarget = ({
  handleExcelImport,
  children,
}: DropTargetProps) => {
  const [isOver, setIsOver] = useState(false);
  const [filetype, setFiletype] = useState("");

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsOver(true);
  }, []);

  const handleDragEnter = useCallback(
    (e) => setFiletype(e.dataTransfer.items[0].type),
    []
  );

  const handleDragExit = useCallback(() => setIsOver(false), []);

  const handleDrop = useCallback(
    (e) => {
      setIsOver(false);
      e.preventDefault();
      const file = e.dataTransfer.files[0];
      if (acceptedTypes.includes(file?.type)) {
        handleExcelImport(file);
      }
    },
    [handleExcelImport]
  );

  return (
    <div
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragExit={() => setIsOver(false)}
      onDragLeave={handleDragExit}
      onDrop={handleDrop}
    >
      {isOver ? <DropZone type={filetype} /> : children}
    </div>
  );
};
