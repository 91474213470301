import { AiFillFileExcel } from "react-icons/ai";

interface ButtonInputProps {
  style?: {};
  extraClass?: string;
  action: (item: File) => void;
}

export const ButtonInput = ({
  style,
  extraClass,
  action,
}: ButtonInputProps) => {
  return (
    <button
      className={
        "flex-1 align-middle shadow-md bg-primary text-white filter hover:brightness-110 active:brightness-90 focus:outline-gold rounded-sm " +
        extraClass
      }
      style={style}
      onClick={() => {
        document.getElementById("excel-import")?.click();
      }}
    >
      <label
        htmlFor="excel-import"
        className="px-4 py-2 w-full h-full flex justify-center items-center gap-2 cursor-pointer"
      >
        <AiFillFileExcel className="text-xl" />
        Import Excel File
      </label>
      <input
        type="file"
        id="excel-import"
        accept=".csv,.xlsx,.xls"
        className="hidden"
        onChange={(e: React.ChangeEvent) => {
          const target = e.target as HTMLInputElement;
          const file: File = (target.files as FileList)[0];
          action(file);
        }}
      />
    </button>
  );
};
