import { Button } from "../base/";
import styled from "styled-components";
import React, { useEffect } from "react";
import { attachListeners } from "../../util";

interface ErrorDisplayProps {
  vis: boolean;
  errors: Error[];
  hide: () => void;
}

interface Error {
  message: string;
  index: number;
}

const StyledModal = styled.div`
  #shadowed {
    box-shadow: 0 -2px 6px #00000010, 0 0 18px #00000015, 0 15px 32px #00000015;
  }

  z-index: 30;
  animation: drop-in 100ms ease;

  @keyframes drop-in {
    from {
      opacity: 0;
    }
  }

  .messageGrid {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 9fr;
  }

  .errorContainer {
    max-height: 600px;
  }

  .min60 {
    min-width: 60px;
  }
`;

export const ErrorDisplay = ({ vis, hide, errors }: ErrorDisplayProps) => {
  useEffect(() => {
    return attachListeners("", ["keydown"], {
      get: vis,
      set: (_: boolean) => hide(),
    });
  });

  return (
    <StyledModal
      style={{ display: vis ? "flex" : "none" }}
      id="transaction-modal-error-wrap"
      onClick={(e: any) => {
        if (e.target.id === "transaction-modal-wrap") hide();
      }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10"
      role="alertdialog"
    >
      <div
        id="shadowed"
        className="container relative w-2/3 p-6 bg-white border-gray-50 rounded border-px"
      >
        <ul className="errorContainer mb-6 overflow-y-scroll">
          <li>
            <div className="messageGrid font-bold border-b-2 content-center items-center">
              <p className="text-center px-4 mr-4 border-r-2 pb-1 h-full min60">
                Line #
              </p>
              <p className="text-left pb-1">Error Message</p>
            </div>
          </li>
          {errors.map((error) => (
            <React.Fragment key={error.message + error.index}>
              <li className="flex flex-col gap-3">
                <div className="messageGrid border-b-2 content-center items-center">
                  <p className="text-center px-4 mr-4 border-r-2 h-full flex items-center justify-center py-1 min60">
                    {error.index + 1}
                  </p>
                  <p className="text-left flex items-center justify-start">
                    {error.message}
                  </p>
                </div>
              </li>
            </React.Fragment>
          ))}
        </ul>
        <Button onClick={hide} text="Continue" style={{ width: "100%" }} />
      </div>
    </StyledModal>
  );
};
