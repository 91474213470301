interface ButtonLinkProps {
  onClick: any;
  style?: {};
  extraClass?: string;
  type?: "submit" | "button";
  children?: React.ReactNode;
  href?: string;
}

export const ButtonLink = ({
  type,
  onClick,
  style,
  extraClass,
  children,
  href,
}: ButtonLinkProps) => {
  return (
    <button
      className={
        "align-middle px-4 py-2 shadow-md bg-primary text-white filter hover:brightness-110 active:brightness-90 focus:outline-gold rounded-sm " +
        extraClass
      }
      onClick={() => {
        if (href) document.getElementById("export-to-excel")?.click();
      }}
    >
      <a
        tabIndex={-1}
        id="export-to-excel"
        href={href}
        download={`journal-upload-${Date.now()}.csv`}
        type={type}
        onClick={onClick}
        style={style}
      >
        {children ? children : ""}
      </a>
    </button>
  );
};
