import React, { useCallback, useMemo } from "react";
import RowComponent from "./Row/RowComponent";
import { RowInputFields } from "../../types";
import styled from "styled-components";
import { RowHeader } from "./RowHeader/RowHeader";
import { DropTarget } from "../utility/DragAndDrop/DropTarget/DropTarget";

interface RowContainerProps {
  rowCount: number;
  getRowChangeHandler: (index: number) => (key: string) => Function;
  rowValues: RowInputFields[];
  handleExcelImport: (file: File) => Promise<void>;
  pageDetail: {
    start: number;
    end: number;
  };
}

const StyledTable = styled.table`
  @media screen and (max-width: 1536px) {
    input {
      min-width: 60px;
      max-width: 100px;
    }
  }
  @media screen and (max-width: 1024px) {
    input {
      min-width: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 520px) {
    font-size: 10px;
  }
`;

const RowContainer = function ({
  rowCount,
  getRowChangeHandler,
  rowValues,
  handleExcelImport,
  pageDetail: { start, end },
}: RowContainerProps) {
  const getRowComponents = useCallback(() => {
    const rows: any[] = [];
    for (let i = 0; i < rowCount; i++) {
      rows.push(
        <React.Fragment key={i}>
          <RowComponent
            busUnit={rowValues[i]?.busUnit}
            speedChart={rowValues[i]?.speedChart}
            account={rowValues[i]?.account}
            fund={rowValues[i]?.fund}
            deptId={rowValues[i]?.deptid}
            program={rowValues[i]?.program}
            klass={rowValues[i]?.class}
            project={rowValues[i]?.project}
            analysisType={rowValues[i]?.analysisType}
            monetaryAmt={rowValues[i]?.monetaryAmt}
            lineDesc={rowValues[i]?.lineDesc}
            lineRef={rowValues[i]?.lineRef}
            getOnChange={getRowChangeHandler(i)}
            index={i}
            rowValues={rowValues}
          />
        </React.Fragment>
      );
    }
    return rows.slice(start, end);
  }, [rowCount, rowValues, start, end, getRowChangeHandler]);

  const headerComponents = useMemo(
    () =>
      [
        "Business Unit",
        "Account",
        "SpeedChart",
        "Fund",
        "DeptID",
        "Program",
        "Class",
        "Project/Grant",
        "Analysis Type",
        "$ Amount",
        "Line Desc.",
        "Line Ref.",
      ].map((field) => (
        <React.Fragment key={field}>
          <RowHeader text={field} />
        </React.Fragment>
      )),
    []
  );

  return (
    <DropTarget handleExcelImport={handleExcelImport}>
      <StyledTable role="table" aria-label="Journal Upload Speedchart Data">
        <thead>
          <tr>
            <td />
            {headerComponents}
          </tr>
        </thead>
        <tbody>{getRowComponents()}</tbody>
      </StyledTable>
    </DropTarget>
  );
};
export default RowContainer;
