import { RowInputFields } from "../types";
import * as XLSX from "xlsx";
import { validations } from "./validations";

export const getParseMethod = async (file: File) => {
  const workbook = XLSX.read(await file.arrayBuffer());
  const sheet = workbook.Sheets.Sheet1;

  if (Object.keys(sheet).length === 1 && sheet.hasOwnProperty("!margins")) {
    return "xls";
  } else {
    return "xlsx";
  }
};

export const handleXLSParsing = async (file: File) => {
  const state: RowInputFields[] = [];
  const workbook = XLSX.read(await file.arrayBuffer());
  const sheet = workbook.Sheets["GL Template"];
  const maxRowVal = parseInt(sheet["!ref"]!.split(":")[1].slice(1)); // A1:L57 -> 57
  const usedColumns = [
    { col: "B", fieldName: "busUnit" },
    { col: "C", fieldName: "account" },
    { col: "D", fieldName: "speedChart" },
    { col: "E", fieldName: "fund" },
    { col: "F", fieldName: "deptid" },
    { col: "G", fieldName: "program" },
    { col: "H", fieldName: "class" },
    { col: "I", fieldName: "project" },
    { col: "J", fieldName: "analysisType" },
    { col: "K", fieldName: "monetaryAmt" },
    { col: "L", fieldName: "lineDesc" },
    { col: "N", fieldName: "lineRef" },
  ];

  for (let row = 16; row <= maxRowVal; row++) {
    const item: { [key: string]: any } = { index: row - 16 };
    usedColumns.forEach((column) => {
      const key = column.col + row;
      const cell = sheet[key];
      if (cell && cell.t === "n") {
        // type number
        // item[column.fieldName] = parseFloat(cell.v).toFixed(2);
        item[column.fieldName] = cell.v.toString();
      } else if (cell && cell.t === "s") {
        // type string
        item[column.fieldName] = cell.v.slice(
          0,
          validations[column.fieldName].max // max character amount
        );
      } else {
        // undefined cell
        item[column.fieldName] = undefined;
      }
      // const value = sheet[key]?.v.toString() || undefined;
      // item[column.fieldName] = value;
    });

    if (!item.busUnit || (!item.speedChart && !item.account)) continue;

    state.push(item as RowInputFields);
  }

  // if any empty rows exist in the sheet then the indeces will skip values.
  // this overwrites the indeces to prevent that.
  const stateWithCompactedIndeces = state.map((item, index) => ({
    ...item,
    index,
  }));

  return {
    rows: stateWithCompactedIndeces,
    journalDate: sheet?.F10?.w ?? "",
    reversalDate: sheet?.K10?.w ?? "",
    busUnit: sheet?.B10?.v ?? "",
    journalId: sheet?.D10?.v ?? "",
    ledger: sheet?.G10?.v ?? "",
    journalSource: sheet?.H10?.v ?? "",
    reversalCode: sheet?.L10?.v ?? "",
    headerDescription: sheet?.A13?.v ?? "",
    headerRef: sheet?.H13?.v ?? "",
  };
};

export const handleXLSXParsing = async (file: File) => {
  const state: RowInputFields[] = [];
  const workbook = XLSX.read(await file.arrayBuffer());
  const sheet = workbook.Sheets.Sheet1;
  const maxRowVal = parseInt(sheet["!ref"]!.split(":")[1].slice(1)); // A1:L57 -> 57
  const usedColumns = [
    { col: "A", fieldName: "busUnit" },
    { col: "B", fieldName: "speedChart" },
    { col: "D", fieldName: "fund" },
    { col: "F", fieldName: "deptid" },
    { col: "H", fieldName: "project" },
    { col: "J", fieldName: "program" },
    { col: "K", fieldName: "class" },
  ];

  for (let row = 3; row <= maxRowVal; row++) {
    const item: { [key: string]: any } = { index: row - 3 };
    usedColumns.forEach((column) => {
      const key = column.col + row;
      const value = sheet[key]?.v || undefined;
      item[column.fieldName] = value;
    });
    state.push(item as RowInputFields);
  }

  return state;
};

export const handleCSVParsing = async (file: File) => {
  const result: string[] = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const result = reader.result as string;
      resolve(result.split("\n"));
    };
  });

  const [headerDescRow, , ...data] = result;
  const [, headerDescription] = headerDescRow.split(",");

  const stateMap: RowInputFields[] = data
    .filter((x) => x)
    .map((fieldString, index) => {
      const f = fieldString.replace(/\r/g, "").split(",");

      return {
        index,
        busUnit: f[0] || "",
        account: f[1] || "",
        speedChart: f[2] || "",
        fund: f[3] || "",
        deptid: f[4] || "",
        program: f[5] || "",
        class: f[6] || "",
        project: f[7] || "",
        analysisType: f[8] || "",
        monetaryAmt: f[9] || "",
        lineDesc: f[10] || "",
        lineRef: f[11] || "",
      };
    });

  return { rows: stateMap, headerDescription };
};
