import { CgSpinner } from "react-icons/cg";
import styled from "styled-components";

interface SpinnerProps {
  vis: boolean;
  size?: string;
}

export const StyledSpinner = styled.div<{ vis: boolean }>`
  display: ${({ vis }: { vis: boolean }) => (vis ? "flex" : "none")};

  .spinner {
    animation: spin 1s infinite linear;
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export default function Spinner({ vis, size }: SpinnerProps) {
  return (
    <div
      className="w-full h-full fixed -top-32 left-0 grid place-items-center z-50"
      style={{ display: vis ? "grid" : "none" }}
    >
      <StyledSpinner vis={vis}>
        <CgSpinner
          style={{ fontSize: size || "1.5rem" }}
          className="spinner text-primary"
        />
      </StyledSpinner>
    </div>
  );
}
